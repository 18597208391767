<template>
  <div>
 
    <b-modal id="MoveQuestionsModalSeries"  ref="MoveQuestionsModalSeries" hide-footer size="lg" centered @show="onModalShow" @hide="onModalHide" scrollable   modal-class="custom-modal" >
      <template #modal-title>
        <div class="d-flex flex-row align-items-center">
          <span>Move Questions</span>
   
        </div>
      </template>

    
  <div class="modal_content " >
  <template v-if="!isLoading">
    <template>
  <div style="margin-top: 20px; margin-bottom: 20px;">
   
    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start; width: 100%;">
      <div style="display: flex; flex-direction: column; width: 48%;">
        <label style="margin-right: 16px; margin-bottom: 8px; font-size: 14px;">
        <input
          type="radio"
          v-model="selectedOption"
          value="existing"
          @change="toggleFields"
          style="width: 14px;
    height: 14px;"/>
        Move to Existing Level
      </label>
        <b-form-select
          v-model="selectedLevelId"
          :options="levelOptions"
          @change="onLevelSelected"
          class="mb-3"
          style="width: 100%;"
          :disabled="selectedOption === 'new'"
        >
          <template #first>
            <option :value="null" disabled>Select a level</option>
          </template>
        </b-form-select>
      </div>

      <!-- Create new level section -->
      <div style="display: flex; flex-direction: column; width: 48%;">
        <label style="margin-bottom: 8px;  font-size: 14px;">
          <input
            type="radio"
            v-model="selectedOption"
            value="new"
            @change="toggleFields"
          style="width: 14px;
    height: 14px;"/>
          Create New Level
        </label>
        <input
          type="text"
          id="newLevel"
          v-model="newLevelName"
          placeholder="Enter new level"
          style="width: 100%; border: 1px solid #d8d6de; padding: 8px; border-radius: 4px; outline: none;"
          :disabled="selectedOption === 'existing'"
        />
      </div>
    </div>
    <div style="min-height: 30px; ">
      <div v-if="!isButtonDisabled">
        <p>
        <strong>Attention: </strong>
        You are going to move <strong> {{ this.selectedQuestionIds.length }} 
        {{ this.selectedQuestionIds.length > 1 ? 'questions' : 'question' }} </strong>
        from <strong>level {{ getCurrentLevelName }}</strong> to <strong> level {{ targetlevelname }}</strong>
      </p>
      </div>
    </div>
    
  </div>
</template>
  </template>
  <div v-else class="text-center"
            style=" display: flex; justify-content: center; align-items: center; min-height: 178px;">
            <b-spinner v-if="isLoading" label="Loading..." medium></b-spinner>
          </div>

  <div class="modal-footer">
    <div class="modal-actions ">
       
        <button class="btn btn-primary"   :disabled="isButtonDisabled" @click="onSave">
         
       <!-- {{ isLoading ? "Moving..." : "Save" }} -->
         Move
        </button>
        <button type="button"  @click="onModalHide"  class="btn btn-outline-secondary  ">
          Cancel
        </button>
        
      </div>
  </div>
</div>

    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
BAlert,
BSpinner,
BTable,
BCard,
BRow,
BCol,
BFormInput,
BPagination,
BMedia,
BAvatar,
BLink,
BBadge,
BDropdown,
BDropdownItem,
BTooltip,
BOverlay,
BFormGroup,
BTableSimple,
BThead,
BFormSelect,
BTr,
BTh,
BTd,
BTbody,
BTfoot,
BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import blueButton from '../../../../public/blue-btn.png'
import purpleButton from '../../../../public/purple-btn.png'
import yellowButton from '../../../../public/yellow-btn.png'
import grayCheck from '../../../../public/grey_check.png'
import blueCheck from '../../../../public/blue_check.png'
import Confetti from '../../../../public/Confetti.json'
import amazoniteButton from '../../../../public/amazonite-btn.png'
import { getDescriptionCode, getOptions } from "@/views/pages/helpers";
import { shortcodes } from "@/views/app/data/data";
import Lottie from 'vue-lottie';
import LottieView from "./LottieView.vue";
import { v4 as uuidv4 } from 'uuid';
import DoLottieView from "./DoLottieView.vue";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  components: {
    BButton,
BAlert,
BSpinner,
BTable,
BCard,
BRow,
BCol,
BFormInput,
BPagination,
BMedia,
BAvatar,
BLink,
BBadge,
BDropdown,
BDropdownItem,
BTooltip,
BOverlay,
BFormGroup,
BTableSimple,
BThead,
BFormSelect,
BTr,
BTh,
BTd,
BTbody,
BTfoot,
BFormCheckbox

  },
  data() {
    return {
      questionText: "",
      alertMessageToast: "",
      currentQuestionIndex: 0,
      colors: ['#FDD835', '#7E57C2', '#42A5F5', '#66BB6A'],
      selectedOptionsArray: [],
      utterance: null,
      descriptionCount:[1],
      optionCount:[1,2,3,4,5],
      blueCheck,
      grayCheck,
      Confetti,
      isTitle: true,
      confettiOptions: {
        loop: false,
        autoplay: true,
        animationData: Confetti
      },
      selectedLevelId: null,
      correctOption: false,
      selectedOption: 'existing',
      selectedCheckBox: [],
      isLoading: true,
      levelsData:[],
      selectedQuestionIds:[],
      currentLevelID: null,
      currentLevelName: null,
      currentCategoryID: null,
      newLevelName: '',
      fields: [
  
        { key: 'questionNo', label: 'Question No' },
        { key: 'theme', label: 'Theme' },
        { key: 'questionType', label: 'Question Type' },
        { key: 'title', label: 'Title' },
        { key: 'titleTts', label: 'Title TTS' },
        { key: 'descriptions', label: 'Descriptions' },
        { key: 'hint', label: 'Hint' },
        { key: 'options', label: 'Options' },
      ],
    };

  },
  
  props: {
    questionsToMoveSeries: {
      type: Array,
      required: true,
    },
    moveQuestionsmodalKey: {
      type: [Number, String],  
      required: false,
    },
  },

  watch: {
  questionsToMoveSeries: {
    immediate: true,
    handler(newVal) {
      if (newVal.length > 0 && newVal[0].categoryId) {
        this.getLevelData(newVal[0].categoryId);
        this.currentLevelID = newVal[0].level_id;
        this.currentCategoryID = newVal[0].categoryId;
      }
   
       this.selectedQuestionIds = newVal.map(item => item._id);
     
    },
  },
  newLevelName: function (newVal) {
    console.log('newVal',newVal)
    // if (newVal.length > 0) {
    //   this.newLevelName = newVal;
    // }
  },
},
   
  computed: {
    targetlevelname() {
    if (this.selectedOption === 'existing') {
      // Find the target level name from levelsData using selectedLevelId
      const targetLevel = this.levelsData.find(level => level._id === this.selectedLevelId);
      return targetLevel ? targetLevel.levelName : "Unknown Level";
    } else if (this.selectedOption === 'new') {
      // Use the new level name entered by the user
      return this.newLevelName || "Unknown Level";
    }
    return "Unknown Level"; // Fallback
  },

    getCurrentLevelName() {
    // Find the level object in levelsData that matches the currentLevelID
    const currentLevel = this.levelsData.find(level => level._id === this.currentLevelID);
    
    // console.log('currentLevel.levelName',currentLevel.levelName)
    // Return the level name if found, otherwise return a default value
    return currentLevel ? currentLevel.levelName : "Unknown Level";
  },


    isButtonDisabled() {
      if (this.selectedOption === 'existing') {
        return !this.selectedLevelId;  
      } else if (this.selectedOption === 'new') {
        return !this.newLevelName;  
      }
      return true;  
    },

    levelOptions() {
    return this.levelsData
      .filter(level => level._id !== this.currentLevelID) 
      .map(level => ({
        value: level._id,
        text: `Level ${level.levelName}`
      }));
  }
  },

  created() {
    this.bearerToken = "Bearer " + localStorage.getItem("_t");
  },
  methods:{
    toggleFields() {
      if (this.selectedOption === 'existing') {
        this.newLevelName = ''; 
      } else if (this.selectedOption === 'new') {
      // Calculate the suggested level name
      this.newLevelName = this.getSuggestedLevelName();
    }
    },

    getSuggestedLevelName() {
  if (this.levelsData.length > 0) {
    // Extract all levelName values (already integers)
    const levelNames = this.levelsData.map(level => level.levelName);

    console.log('levelNames:', levelNames);

    // Find the maximum levelName value
    const maxLevelNumber = Math.max(...levelNames);

    // Return the next level name
    return maxLevelNumber + 1;
  } else {
    console.error('Error while getting suggested level name: levelsData is empty');
    return 1; // Default to 1 if levelsData is empty
  }
},

    setInputFocusStyle(event) {
    event.target.style.borderColor = '#d8d6de';  
  },
  resetInputStyle(event) {
    event.target.style.borderColor = '#d8d6de';  
  },

    onSave(){
      let msg =
      this.selectedQuestionIds.length > 1
                    ? "Do You want to move questions!"
                    : "Do You want to move question!";
            Swal.fire({
                title: "Are you sure?",
                text: msg,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: `Yes, move ${this.selectedQuestionIds.length > 1? 'them':'it'}!`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.moveQuestions();
                }
            });
    },

    async moveQuestions() {
      // console.log('this.newLevelName',this.newLevelName)
      // return;
  this.isLoading = true; // Set loading state
  try {
    // Construct the payload based on the selected option
    const payload = {
      questionIds: this.selectedQuestionIds || [], // Always include questionIds
      ...(this.selectedOption === "existing"
        ? { levelId: this.selectedLevelId || 0 } // Include levelId if "existing"
        : { 
          level_name: parseInt(this.newLevelName, 10) || null,
            // level_name: this.newLevelName || null, // Include level_name for new level
            NoofQuestion: 10, // Static value for number of questions
            selectStatus: "inactive", // Static value for status
            sub_category_id: this.currentCategoryID || null, // Include sub_category_id
          }),
    };

    // Make the API call
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/level/move-questions`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("_t")}`, // Include auth token
        },
      }
    );

    // Handle success
    this.alertMessageToast = response.data.message || "Questions moved successfully!";
    this.showToast("success");
    this.$emit("getUpdatedQuestionsDataSeries"); // Emit event to refresh data
  } catch (error) {
    // Handle error
    console.error("Error moving questions:", error);
    this.alertMessageToast = "Error moving questions. Please try again.";
    this.showToast("danger");
  } finally {
    this.isLoading = false; // Reset loading state
    this.onModalHide(); // Hide the modal
  }
},
    onLevelSelected(levelId) {
      console.log("Selected Level Namexxx:", this.selectedLevelId);
    },
  
    async getLevelData(id) {
    this.isLoading = true; 
    try {
      const get_response = await axios.get(
        process.env.VUE_APP_API_URL + `/category-levels/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        }
      );
      this.levelsData = get_response.data.level; 
    } catch (error) {
      this.alertData = "Try again, something went wrong!";
      this.showToast("danger");
      console.error("Error fetching level data:", error);
      this.onModalHide();
    } finally {
      this.isLoading = false;  
    }
  },

    showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
    getFileExtensionFromUrl(url) {
            if (!url) return '';
            const path = url.split('?')[0].split('#')[0];
            const extension = path.split('.').pop();

            if (extension === path) return '';

            return extension;
        },

    onModalShow(){
     

    },
    onModalHide() {
      this.questionsToMoveSeries = []; 
         this.$refs.MoveQuestionsModalSeries.hide();
      this.selectedQuestionIds = [];
      this.selectedLevelId = null;
      this.levelsData = []; 
      this.$emit("ResetQuestionsSeries");
      this.newLevelName= '';
      this.selectedOption= 'existing';

    },
   
  },



};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

img.model_backgroundimage {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: -webkit-fill-available;
}

.modal_content {
  position: relative;
  z-index: 2;
}

.modal_feedback_data {
  position: relative;
  z-index: 2;
  height: 400px;
}

.highlight-row {
  background: #ff4d4d;
}
</style>

<style>

.modal-actions button {
  margin: 5px;
  /* padding: 12px 24px;
  font-size: 1.2rem;
  border: none;
  border-radius: 4px; */
  cursor: pointer;
}

.save-button {
  background: #32aaed;
  color: white;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.save-button:disabled {
  background: #32aaed;
  color: #fff;
  opacity: 0.5;
  cursor: not-allowed;
}

.cancel-button {
  background: #f44336;
  color: white;
}

/* .input-field{ */
  .input-field:focus {
    border-color: #32aaed !important;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
    border-width: 1px;
}
/* } */
</style>